const { api } = require('./Api')
import store from '../state/store'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)


async function getClosedDates({ startDate, endDate, warehouse }) {
  const result = await api.get('v2/closingDays', {
    params: {
      warehouse: warehouse ?? store.getters['rentalInformation/warehouse'],
      dateFrom: startDate ?? dayjs().format('YYYY-MM-DD'),
      dateTo:
        endDate ??
        dayjs()
          .add('180', 'days')
          .format('YYYY-MM-DD')
    }
  })

  return result.data
}

module.exports = {
  getClosedDates
}
