<template>
  <div class="form-group">
    <label>Afhaallocatie</label>
    <b-form-select
      v-model="selection"
      size="md"
      :options="warehouses"
      placeholder="Selecteer een afhaallocatie"
    >
      <template #first>
        <b-form-select-option :value="null" disabled
          >Selecteer een afhaallocatie</b-form-select-option
        >
      </template>
    </b-form-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WarehouseSelector',
  data() {
    return {
      selection: null
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/getWarehouses',
      selectedWarehouse: 'rentalInformation/warehouse'
    })
  },
  watch: {
    selection(newValue) {
      this.$store.commit('rentalInformation/setSelectedWarehouse', newValue)
    }
  },
  created() {
    this.selection = this.selectedWarehouse
  }
}
</script>

<style scoped></style>
